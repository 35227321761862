window.jQuery = window.$ = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle');

$(document).ready(function() {
    $(".modal").modal();

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $(".btn-plus").click(function() {
        $input = $(this).parents(".input-group").find("input[type=number]");
        val = parseInt($input.val()) + 1;
        if (!$input.attr('max') || val <= parseInt($input.attr('max'))) {
            $input.val(val);
            $input.trigger('change');
        }
    })
    $(".btn-minus").click(function() {
        $input = $(this).parents(".input-group").find("input[type=number]");
        val = parseInt($input.val()) - 1;
        if (!$input.attr('min') || val >= parseInt($input.attr('min'))) {
            $input.val(val);
            $input.trigger('change');
        }
    })

    $(".notebook-counts input[type=number]").change(function() {
        if ($(this).val() > 0) {
            $(this).addClass('is-valid');
        } else {
            $(this).removeClass('is-valid');
        }
    }).trigger('change');

    $("#randomCovers").click(function() {
       $("select[name*=covers]").each(function(i, e) {
           $(e).children('option').eq(Math.floor(Math.random() * ($(e).children('option').length-1))+1).prop('selected', true);
       });
    });

    $(".add-product-form input").change(function() {
        let $form = $(this).closest('form');
        addProductAjax($form);
    });

    $(".mass-add-product-form [type=submit]").click(function(e) {
        e.preventDefault();
        let $form = $(this).closest('form');
        addProductAjax($form);
    });

    function addProductAjax($form) {
        $form.find('input, .input-group').removeClass('is-valid').removeClass('is-invalid');
        $form.find('button[type=submit] .spinner-border').removeClass('d-none');
        $form.find('.invalid-feedback').text();
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(response) {
                $form.find('button[type=submit] .spinner-border').addClass('d-none');
                if (response.state === 'success') {
                    $form.find('input, .input-group').addClass('is-valid');
                    let $totalProductPrice = $form.closest('tr').find('.total-product-price');
                    if ($totalProductPrice.length > 0) {
                        $totalProductPrice.text(($totalProductPrice.attr('data-unit-price') * $form.find('input[name=count]').val()).toLocaleString('cs-CZ', {minimumFractionDigits: 2, maximumFractionDigits: 2}))
                        let $table = $form.closest('table');
                        let totalPrice = 0;
                        $table.find('tr').each(function() {
                            totalPrice += ($(this).find('.total-product-price').attr('data-unit-price') ?? 0) * ($(this).find('input[name=count]').val() ?? 0);
                        });
                        $table.find('.total-price').text(totalPrice.toLocaleString('cs-CZ', {minimumFractionDigits: 2, maximumFractionDigits: 2}));
                    }
                    Object.keys(response.update ?? {}).forEach(function(key) {
                        $(key).html(this[key]);
                    }, response.update ?? {});
                } else {
                    $form.find('input, .input-group').addClass('is-invalid');
                    $form.find('.invalid-feedback').text(response.message);
                }
            },
            error: function() {
                $form.find('button[type=submit] .spinner-border').addClass('d-none');
                $form.find('input, .input-group').addClass('is-invalid');
                $form.find('.invalid-feedback').text('Nastala neznámá chyba.');
            }
        })
    }
});
